import React, { Component } from 'react';
import './ErrorPage.css';
import Strings from "../../text/strings";

/**
 * Class for generic ErrorPage
 */
class ErrorPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      strings: this.props.strings,
    }
  }

  componentDidMount() {
    if(this.state.strings === undefined) {
      this.setState({strings: Strings.Strings.enStrings})
    }
  }


  render() {
    return (
        <div className="col-sm-9 d-flex align-items-center justify-content-center errorPosition">
          <div className="text-center">
           <h1><b>{this.state.strings.errorTextHeader}</b></h1>
            <h2 className="errorText">{this.state.strings.errorText}</h2>
            <br/>
            <a className="btn errorButton btn-lg" href="/#/"><h3>{this.state.strings.back}</h3></a>
          </div>
        </div>
    )
  }
}
export default ErrorPage;