import { Component } from 'react';
import ApolloClient from "apollo-boost";
import gql from "graphql-tag";
import strings from '../text/strings'
import CookieService from "../services/CookieService";
class GraphqlQueries extends Component{

    constructor(props){
        super(props)
        this.state = {
            cookieService: new CookieService(),
            client: new ApolloClient({
                uri: strings.Strings.devStrings.prodUri + "/graphql",
            }),
        }
        
    }
    

  /**
   * Used to get the most recent company name with help of an email address.
   * @param email Email of a visitor.
   * @returns {Promise<ApolloQueryResult<any>>}
   */
    getCompanyByMail(email) {
      return this.state.client
        .query(({
          query: gql`query($email:String!){
                 companyByEmail(email: $email){
                company
              } 
            }
          `, variables: {email: email}
        }))
    }

    getVisitorById(id){
        return this.state.client
            .query({
                query: gql`query($id:ID!, $token:String!){
                        visitor(id: $id, token: $token) {
                          id
                          nda
                          name
                          date
                          email
                          phone
                          company
                        }
                        }`
                , variables: {id: id, token: this.state.cookieService.getToken()}
            })
    }

    getVisitorsByName(name) {
        return this.state.client
            .query({
                query: gql`query($name:String!, $token:String!){
                        visitorsByName(name: $name, token: $token) {
                          id
                          nda
                          name
                          date
                          email
                          phone
                          company
                        }
                        }`
                , variables: {name: name, token: this.state.cookieService.getToken()}
            })
    }
  
    getVisitorsByCompany(company) {
        return this.state.client
            .query({
                query: gql`query($company:String!, $token:String!){
                        visitorsByCompany(company: $company, token: $token) {
                          id
                          nda
                          name
                          date
                          email
                          phone
                          company
                        }
                        }`
                , variables: {company: company, token: this.state.cookieService.getToken()}
            })
    }

    getVisitorByEmail(email) {
        return this.state.client
            .query({
                query: gql`query($email:String!, $token:String!){
                        visitorByEmail(email: $email, token: $token) {
                          id
                          nda
                          name
                          date
                          email
                          phone
                          company
                        }
                        }`
                , variables: {email: email, token: this.state.cookieService.getToken()}
            })
    }


  getAdminByEmail(email) {
    return this.state.client
      .query({
        query: gql`query($email:String!){
                        adminByEmail(email: $email) {
                          id
                          role
                          name
                          email
                          password
                        }
                        }`
        , variables: {email: email}
      })
  }

    getVisitorExistsByEmail(email) {
        return this.state.client
            .query({
                query: gql`query($email:String!){
                        visitorExistsByEmail(email: $email) {
                            success
                        }
                        }`
                , variables: {email: email}
            })
    }


    getAllVisitors(){
        return this.state.client
            .query({
                query: gql`query($token:String!){
                    visitorGetAll(token: $token){
                            id
                            company
                            name
                            email
                            phone
                            date
                            nda
                        }
                    }`
            , variables: {token: this.state.cookieService.getToken()}
            })
    }

    addVisitor(visitor){
        const name = visitor.name;
        const company = visitor.company;
        const email = visitor.email;
        const phone = visitor.phone;
        const nda = true;
        
        return this.state.client
            .mutate({
                mutation: gql`mutation($name: String!, $company: String!, $email: String!, $phone: String!, $nda: Boolean!){
                    createVisitor(visitorInput: {
                        name: $name
                        company: $company
                        email: $email
                        phone: $phone
                        nda: $nda
                    }){
                        success
                       }
                }`
                , variables: {name: name, company: company, email: email, phone: phone, nda: nda}
            })
    }
    
    returningVisitor(visitorEmail){
        const email = visitorEmail;
        const updatedNda = true;

        return this.state.client
            .mutate({
                mutation: gql`mutation($email: String!, $updatedNda: Boolean!){
                    updateVisitor(
                        email: $email
                        updatedNda: $updatedNda
                    ){
                        success
                    }
                }`
                , variables: {email: email, updatedNda: updatedNda}
            })
    }

  /**
   * Service function against graphql.
   * Deletes one visitor from the database, this with help
   * of the visitor id
   * @param visitorId id of the visitor to delete
   * @returns {Promise<FetchResult<any>>}
   */
    deleteVisitor(visitorId){
      return this.state.client.mutate(
        {
          mutation: gql`
            mutation($id: String!, $token: String!){
              deleteVisitorOne(id: $id, token: $token)
                {
                  success
                }
             }
          `,
          variables: {id: visitorId, token: this.state.cookieService.getToken()}
        }
      )
    }

  /**
   * Service function for graphql.
   * Deletes one visitor from the database, this with help
   * of the visitor id
   * @param visitorId id of the visitor to delete
   * @returns {Promise<FetchResult<any>>}
   */
  sendMailToVisitor(email, lang){
    return this.state.client.mutate(
      {
        mutation: gql`
            mutation($email: String!, $lang: String!){
              sendMailToVisitor(email: $email, lang: $lang)
                {
                  success
                  message
                }
             }
          `,
        variables: {email: email, lang: lang}
      }
    )
  }

    /**
     * Service function for graphql.
     * Adds a new admin to the database.
     * @param name
     * @param email
     * @param password
     * @returns {Promise<FetchResult<any>>}
     */
  addNewAdmin(name, email, password) {
      return this.state.client.mutate(
          {
              mutation: gql`
                  mutation ($name: String!, $email: String!, $password: String!, $token: String!){
                      addNewAdmin(name: $name, email: $email, password: $password, token: $token)
                      {
                          success
                          message
                      }
                  }
              `,
              variables: {name: name, email: email, password: password, token: this.state.cookieService.getToken()}
          }
      )
  }

    /**
     * Checks if admin exist in database.
     *
     * @param email
     * @returns {Promise<ApolloQueryResult<any>>}
     */
  adminExistByEmail(email) {
      return this.state.client.query(
          {
              query: gql`
                  query ($email: String!, $token: String!){
                      adminExistByEmail(email: $email, token: $token)
                      {
                          success
                          message
                      }
                  }
              `,
              variables: {email: email, token: this.state.cookieService.getToken()}
          }
          )
    }
}
export default GraphqlQueries;
