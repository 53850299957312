class CookieService {
  /**
   * Retreives only the token from cookies. If no token is found it returns an empty string.
   */
    getToken(){
        let sessionCookie = this.getCookie("BoxmodulSession")
        return(sessionCookie.replace("Bearer ", ""));
      }
      
      /**
       * This is a safe way of extracting a specific cookie value from all the cookies.
       * @param cname the name of the cookie
       */
      getCookie(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for(var i = 0; i <ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) === ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
      }
}
export default CookieService;