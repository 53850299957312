import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './components.css';
import '../App.css';
import PopupWindow from './PopupWindow';

class NewVisitorForm extends Component {
    constructor(){
        super();
        this.state = {
            newVisitor:{},
            popup: false,
            countryCode: "+46"
        }
    }

    handleSubmit(e){
        this.setState({newVisitor:{
            name: this.refs.name.value,
            email: this.refs.email.value,
            phone: 1,
            company: this.refs.company.value

        }}, function(){
            this.props.newVisitor(this.state.newVisitor)
            this.props.changeView('nda')
        })
        e.preventDefault();
    }

    checkFields(){
        var name = document.getElementById("nameInput").value;
        var email = document.getElementById("emailInput").value;
        var phone = 1;
        var company = document.getElementById("companyInput").value;
        if(name && (email.length > 3 && (email.toString().indexOf("@") !== -1) && (email.toString().split("@")[1].length > 0)) && phone && company){
            document.getElementById("submitForm").disabled = false;
            document.getElementById("submitForm").className="btn box-button-left-blue";
        }else{
            document.getElementById("submitForm").className="btn box-button-left";
            document.getElementById("submitForm").disabled = true;
        }
    }

    handleChangeView(view){
        this.props.changeView(view)
    }

    componentDidMount(){
        this.setState({popup:this.props.showPopup})
    }
    
    dismissPopup(){
        this.setState({popup: false})
        this.props.changeView('homepage')
    }

   /**
    * Filters out all characters except number from the phone input
    */
    filterPhone(){
      document.getElementById("phoneInput").value = document.getElementById("phoneInput").value.replace(/[^0-9]/,'');
    }

    helpPopUp(){
      return (
        <div className="offset-sm-3 modal fade" tabIndex="-1" role="dialog" id="inputHelp" data-keyboard="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h2 className="modal-title" id="inputHelplable"><b>{this.props.strings.help}</b></h2>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <h3  className="modal-help-text"><b><u>{this.props.strings.explanation}</u></b></h3>
                    <p className="modal-help-text">
                      <b>{this.props.strings.name}:</b> {this.props.strings.helpFirstAndSureName} <br/>
                      <b>{this.props.strings.email}:</b> {this.props.strings.helpMail} <br/>
                      <b>{this.props.strings.company}:</b> {this.props.strings.helpCompany} <br/>
                    </p>
                  <br/>
                  <h3  className="modal-help-text"><b><u>{this.props.strings.example}</u></b></h3>
                  <p className="modal-help-text">
                    <b>{this.props.strings.name}:</b> Box Boxmodul <br/>
                    <b>{this.props.strings.email}:</b> no-reply@boxmodul.se <br/>
                    <b>{this.props.strings.company}:</b> Boxmodul <br/>
                  </p>
                </div>
                <div className="modal-footer">
                  <button type="button" data-dismiss="modal" className="btn btn-lg modal-help-button button-text App-DarkBlueBackgroundcolour">{this.props.strings.dismiss}</button>
                </div>
              </div>
          </div>
        </div>
      )
    }

    render(){
        return (
        <div className="col-sm-9 home-container">

            {this.state.popup &&
                <PopupWindow dismissAction={this.dismissPopup.bind(this)} strings={this.props.strings} popupText={this.props.strings.popupThanks} buttonText={this.props.strings.newVisit}/>
            }
            {this.helpPopUp()}

              <h1 className="form-title" id="newVisitTitle">{this.props.strings.newVisitor}</h1>
                <form className="box-content form-group" autoComplete="off" onSubmit={this.handleSubmit.bind(this)}>
                  <div className="d-flex justify-content-start">
                    <h1>
                      <i data-toggle="modal" data-target="#inputHelp">
                        <span className="glyphicon glyphicon-question-sign"></span>
                      </i>
                    </h1>
                  </div>
                  <div className="input-group">
                    <input id="nameInput" onChange={this.checkFields} type="text" ref="name" className="box-input form-control info-input" placeholder={this.props.strings.name}/>
                    <div className="input-group-append dropup">
                        <button id="inputInfo" className="btn btn-outline-secondary glyphicon glyphicon-info-sign box-input" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button>
                        <div className="dropdown-menu dropdown-menu-input-name dropdown-menu-right">
                          <button className="dropdown-item dropdown-item-input-name">Ex: Box Boxmodul</button>
                        </div>
                    </div>
                  </div>

                  <br/>
                  <div className="input-group">
                    <input id="emailInput" onChange={this.checkFields} type="email" ref="email" className="box-input form-control info-input" placeholder={this.props.strings.email}></input>
                    <div className="input-group-append dropup">
                      <button id="inputInfo" className="btn btn-outline-secondary glyphicon glyphicon-info-sign box-input" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button>
                      <div className="dropdown-menu dropdown-menu-input-name dropdown-menu-right">
                        <button className="dropdown-item dropdown-item-input-name">Ex: no-reply@boxmodul.se</button>
                      </div>
                    </div>
                  </div>

                  <br/>
                  <div className="input-group">
                    <input id="companyInput" onChange={this.checkFields} type="text" ref="company" className="box-input form-control info-input" placeholder={this.props.strings.company}></input>
                    <div className="input-group-append dropup">
                      <button id="inputInfo" className="btn btn-outline-secondary glyphicon glyphicon-info-sign box-input" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button>
                      <div className="dropdown-menu dropdown-menu-input-name dropdown-menu-right">
                        <button className="dropdown-item dropdown-item-input-name">Ex: Boxmodul</button>
                      </div>
                    </div>
                  </div>

                  <br/>
                  <div className="input-group">
					<button id="submitForm" disabled type="submit" className="btn box-button-left" >{this.props.strings.register}</button>
					<button onClick={this.handleChangeView.bind(this, 'homepage')} className="btn box-button-right" >{this.props.strings.back}</button>
                  </div>
                </form>
        </div>

        );
    }
}

NewVisitorForm.propTypes = {
    newVisitor: PropTypes.func
}

export default NewVisitorForm;
