import React, {Component} from 'react';
import '../App.css';
import './Admin.css';

class AdminLoginNav extends Component {

  render() {
    return(

      <nav className="navbar App-DarkBlueBackgroundcolour col">
        <div className="container-fluid Admin-medium">
          <img className= "Admin-medium col-sm-2" alt="box-modul-loggo" type="image" src={require("../assets/static/pictures/BoxModul_White_RGB.png")}/>
          <div className="btn-group Admin-medium">
          </div>
        </div>
      </nav>

    );
  }
}

export default AdminLoginNav;