import React, {Component} from 'react';
import './BaseSidebar.css';

class BaseSidebar extends Component {

    render() {
        return (
            <div className="base-side col-sm-3 base-medium" >
                <div className="v-center"></div>
                <img className="base-logo" alt="box-modul-loggo" src={require('../../assets/static/pictures/BoxModul_White_RGB.png')}/>
            </div>
        )
    }
}

export default BaseSidebar;