import React, { Component } from 'react';
import '../App.css';
import GraphqlQueries from '../services/GraphqlQueries';
import { Redirect } from 'react-router-dom';
import Strings from '../text/strings';
import strings from '../text/strings';
import CookieService from "../services/CookieService";


class AdminTable extends Component{
    constructor(props){
        super(props);
        this.state = {
            listItems: <tr></tr>,
            visitors: [],
            nameReverse: false,
            companyReverse: false,
            emailReverse: false,
            ndaReverse: true,
            dateReverse: false,
            redirectToLogin: (!this.tokenIsPresent()),
            redirectToError: false,
            strings: Strings.Strings.sweStrings,
            allVisitors: [],
            isChecked: {},
            checkedItemsVisable: 0,
        }
    }

    componentDidMount(){
        if(this.tokenIsPresent()){
            const graph = new GraphqlQueries()
            graph.getAllVisitors().then(data =>{
                // if a token exists but is not validated it will return null. Then redirect to error view
                if(data.data.visitorGetAll != null){
                    this.init(data.data.visitorGetAll);
                }else{
                    this.setState({ redirectToLogin:true })
                }
            }, error => this.setState({redirectToError: true}))
        }else{
            this.setState({redirectToLogin: true})
        }
    }

  /**
   * Initialise the admin view, this to avoid race conditions
   * The list will be reversed  so that the date will be in order
   */
  init(data){
    let visitors = data.slice().reverse();
    this.setAll(visitors);
    this.setVisitors(visitors);
    this.setList(visitors);
    var isCheckedArr = {};
    for(var i = 0; i < visitors.length; i++){
      isCheckedArr[visitors[i].id] = false
    }
    this.setState({isChecked: isCheckedArr})
    }

    /**
     * Checks if there is a token persent but does not validate it
     */
    tokenIsPresent(){
        return new CookieService().getToken() !== ''
    }

    /**
     * Creates the inner table of visitors
     * @param data array with visitors to show
     */
    setList(data){
      var newList = [];
      if(data.length === 0){
        this.setState({listItems: newList});
        this.checkList(this.state.checkedList);
        this.updateCheckedList();
      } else {
        for(var i = 0; i < data.length; i++) {
          if (i < data.length)
            newList.push(<tr key={data[i].id}>
              <td><input name="checkBox" value={data[i].id} key={data[i].id} onClick={() => this.updateCheckedList()}
                         type="checkbox"/></td>
              <td>{data[i].date}</td>
              <td>{data[i].name}</td>
              <td>{data[i].company}</td>
              <td>{data[i].email}</td>
              <td>{this.translateBool(data[i].nda)}</td>
            </tr>);

          if (i + 1 === data.length) {
            this.setState({listItems: newList});
            this.checkList(this.state.checkedList);
            this.updateCheckedList();
          }
        }
      }
    }

  /**
   * Setter function to set all visitors
   * @param data Array with all visitors
   */
  setAll(data){
      this.setState({allVisitors: data});
    }

  /**
   * Sets the global visitors states to the input parameter.
   * @param visitors Array with visitors
   */
  setVisitors(visitors){
    this.setState({visitors: visitors});
    }

  /**
   * Function to update the visitor table
   */
  updateTable(){
    const graph = new GraphqlQueries()
    graph.getAllVisitors().then(data =>{
      let visitors = data.data.visitorGetAll.slice().reverse();
      this.setAll(visitors);
      this.setVisitors(visitors);
      this.setList(visitors);
    }, error => this.setState({redirectToError: true}))
  }

  /**
   * Function to check all boxes in the list
   */
  checkAll(){
      if(document.getElementById("checkAll").checked) {
        for(var i = 0; i < document.getElementsByName("checkBox").length; i++) {
          document.getElementsByName("checkBox")[i].checked = true;
        }
      }else {
          for(i = 0; i < document.getElementsByName("checkBox").length; i++) {
            document.getElementsByName("checkBox")[i].checked = false;
          }
        }

    this.updateCheckedList()
  }

  /**
   * Checks the boxes corresponding to the given list of id's
   * @param checkedItemsList a list of id's
   */
  checkList(checkedItemsList){
    for(var i =0; i < document.getElementsByName("checkBox").length; i++){
      if(this.state.isChecked[document.getElementsByName("checkBox")[0].value]){
        document.getElementsByName("checkBox")[0].checked = true;
      }
    }
  }

  /**
   * Helper function to function deleteVisitor. This was created due to warning "no-loop-func".
   * @param index The index of which checkbox to fetch data from.
   */
  deleteHelper(index){
    (new GraphqlQueries()).deleteVisitor(document.getElementsByName("checkBox")[index].value).then(
      data => {
        if (!data.data.deleteVisitorOne.success) {
          this.setState({redirectToError: true})
        }
      },
      err => this.setState({redirectToError: true}));
  }

  /**
   * Function to delete visitor, this based on which checkboxes that is currently checked in the visitor table.
   */
  deleteVisitor(){
      for(var i = 0; i < document.getElementsByName("checkBox").length; i++){
        if(document.getElementsByName("checkBox")[i].checked) {
          this.deleteHelper(i);
        }
      }
      this.updateTable();
    }


  /**
   * Sets the export url for the export button in the export popup window.
   * The url is dependent on which checkboxes that is checked. If a box is checked
   * then that value will be appended to the export url.
   */
  exportVisitors(){
    let exportVisitors = '';
    let exportedVisitors = 0;

    for(var i =0; i < document.getElementsByName("checkBox").length; i++) {
      if(document.getElementsByName("checkBox")[i].checked) {
        exportVisitors = exportVisitors + document.getElementsByName("checkBox")[i].value + ",";
        exportedVisitors++;
      }
    }
    
    if(exportedVisitors > 0){
      document.getElementById("exportForm").action = strings.Strings.devStrings.prodUri + "/download/" + exportVisitors + "/" + new CookieService().getToken();
    }else{
      document.getElementById("exportVisitor").href = "#";
    }
  }
  
  /**
   * Updates the state of the internal state that keeps track of which ids should be checked.
   * Also updates a counter of how many of the visable items that is checked.
   */
  updateCheckedList(){
    var isCheckedArr = this.state.isChecked;
    var checkedItemsCurrentlyVisable = 0;
    var element = document.getElementsByName("checkBox");
    if( element != null){
      for(var i = 0; i < document.getElementsByName("checkBox").length; i++) {
        if(document.getElementsByName("checkBox")[i].checked) {
          isCheckedArr[document.getElementsByName("checkBox")[i].value] = true;
          checkedItemsCurrentlyVisable++
        }else{
          isCheckedArr[document.getElementsByName("checkBox")[i].value] = false;
        }
      }
      this.setState({ 
          isChecked:isCheckedArr ,
          checkedItemsVisable: checkedItemsCurrentlyVisable
      }, function(){
          if (this.allIsChecked()) {
              document.getElementById("checkAll").checked = true
          }else{
              document.getElementById("checkAll").checked = false
          }
      })
    }
  }

  /**
   * Checks if all visable checkboxes are checked then returns true or else false
   */
  allIsChecked(){
    var isChecked = true;
    for(var i = 0; i < document.getElementsByName("checkBox").length; i++) {
      if(!(document.getElementsByName("checkBox")[i].checked)){
        isChecked = false;
      }
    }
    return isChecked;
}

  /**
   * Shows a warning popup to confirm export of visitors or not.
   * @returns {*}
   */
  exportPopUp(){
    return(
    <div className="modal fade " tabIndex="-1" role="dialog" id="exportWarning" data-keyboard="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h3 className="modal-title" id="exportWarninglable">Varning</h3>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            Är du säker på att du vill exportera de {this.state.checkedItemsVisable} markerade användarna?
          </div>
          <form id ="exportForm" type="get" action="" className="">
            <div className="modal-footer">
              <button id = "exportVisitorButton" className="btn btn-lg button-text App-DarkBlueBackgroundcolour button-deletePopup">Exportera</button>
              <button data-dismiss="modal" className="btn btn-lg button-text App-DarkBlueBackgroundcolour button-deletePopup" type="button" >Stäng</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    )
  }

  /**
   * Show a warning popup to confirm delete of visitors or not.
   * @returns {*}
   */
  deletePopup(){
      return(
          <div className="modal fade" tabIndex="-1" role="dialog" id="deleteWarning" data-keyboard="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h3 className="modal-title" id="deleteWarninglable">Varning</h3>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                       Är du säker på att du vill radera de {this.state.checkedItemsVisable} markerade användarna?
                </div>
                <div className="modal-footer justify-content-center">
                    <button onClick={() => this.deleteVisitor()} data-dismiss="modal" type="button" className="btn btn-lg mr-4 button-text btn-danger button-deletePopup" >JA</button>
                    <button type="button" data-dismiss="modal" className="btn btn-lg button-text App-DarkBlueBackgroundcolour button-deletePopup">NEJ</button>
                </div>
              </div>
            </div>
          </div>

      )
    }

    /**
     * Translate from true or false to "Ja" or "Nej" ,depending on bool state.
     * @param nda bool to translate
     * @returns {string} the translated string
     */
    translateBool(nda){
        if(nda.toString() === "false"){
            return "Nej"
        }else if (nda.toString() === "true"){
            return "Ja"
        } else{
            return "Ej giltlig"
        }
    }


  /**
   * Checks if a substrings is included in the main string and then returns a boolean. If the substring is included in the main string
   * then the function returns a bool with value true. Otherwise false is returned.
   * @param string Main string to compare with
   * @param substring Substring to compare with the main string.
   * @returns {boolean} Returns true if the substring occurs in the main string. Otherwise a false is returned.
   */
    compareStrings(string, substring){
      if(string.indexOf(substring) !== -1){
        return true
      }else{
        return false
      }
    }

    /**
     * Filters the visitor based on input from input searchbox
     * @param size set too small if small devices, leaveblank otherwise
     */
    filterVisitors(size){
        if(size === "small") {
            var checkName = document.getElementById("adminSearchSmall").value;
        }else{
             checkName = document.getElementById("adminSearch").value;
        }
        this.setList(this.state.visitors.filter(element =>
        this.compareStrings(element.date.toLowerCase(),checkName.toLowerCase()) ||
        this.compareStrings(element.name.toLowerCase(),checkName.toLowerCase()) ||
        this.compareStrings(element.company.toLowerCase(),checkName.toLowerCase()) ||
        this.compareStrings(element.email.toLowerCase(),checkName.toLowerCase())));
    }

    /**
     * Sortes the table based on date
     * @param data data array with visitors to be sorted
     */
    sortTableDate(data){
        var arrayDate = data.slice();
        this.setList(arrayDate.sort((a,b) =>{
            if(this.state.dateReverse){
                return new Date(b.date) - new Date(a.date)
            }else{
                return new Date(a.date) - new Date(b.date)
            }
        }));
        this.setState({dateReverse: !this.state.dateReverse});
    }

    /**
     * Sorts the table based on either name, nda, company, or email
     * @param data data array with visitors to be sorted
     * @param attribute attribute to sort after
     */
    sortTable(data, attribute){
        var reversed = "";
        var arrayVis = data.slice()
        this.setList(arrayVis.sort(((visitorA, visitorB) => {
            if (attribute === 'name') {
                var nameA = visitorA.name.toLowerCase(); // ignore upper and lowercase
                var nameB = visitorB.name.toLowerCase();
                reversed = this.state.nameReverse.valueOf();
                this.setState({nameReverse: !this.state.nameReverse});
            } else if (attribute === 'company') {
                 nameA = visitorA.company.toLowerCase(); // ignore upper and lowercase
                 nameB = visitorB.company.toLowerCase();
                reversed = this.state.companyReverse.valueOf();
                this.setState({companyReverse: !this.state.companyReverse});
            } else if (attribute === 'email') {
                 nameA = visitorA.email.toLowerCase(); // ignore upper and lowercase
                 nameB = visitorB.email.toLowerCase();
                reversed = this.state.emailReverse.valueOf();
                this.setState({emailReverse: !this.state.emailReverse});
            } else if (attribute === 'nda') {
                 nameA = visitorA.nda.valueOf().toString().toLowerCase(); // ignore upper and lowercase
                 nameB = visitorB.nda.valueOf().toString().toLowerCase();
                reversed = this.state.ndaReverse;
                this.setState({ndaReverse: !this.state.ndaReverse});
            }
            if(reversed === false) {
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            }else{
                if (nameB < nameA) {
                    return -1;
                }
                if (nameB > nameA) {
                    return 1;
                }
                return 0;
            }
        })))
    }


    /**
     * Creates the headerbuttons for the table
     * @returns {*}
     */
    headerButtons() {
        return (
            <div className="container-fluid button-bar ">
              {this.deletePopup()}
              {this.exportPopUp()}
                <div className="row Admin-medium">
                    <div className="col-sm-6 btn-group mr-auto">
                      <button className="btn btn-lg header-button-color mr-2 " data-toggle="modal" data-target="#exportWarning" onClick={this.exportVisitors.bind(this)}>Exportera
                        </button>
                      <button className="btn btn-lg header-button-color " data-toggle="modal" data-target="#deleteWarning">Radera</button>
                    </div>
                    <input id="adminSearch" type="text" className="form-control col-sm-6 " placeholder="Sök..." ref="adminSearch" onChange={() => this.filterVisitors()}/>
                </div>

                <div className="Admin-small">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <button className="btn btn-outline-secondary dropdown-toggle" type="button"
                                    data-toggle="dropdown">...
                            </button>
                            <div className="dropdown-menu dropdown-menu-right">
                              <button type="Button" className="dropdown-item Admin-small" data-toggle="modal" data-target="#exportWarning" onClick={this.exportVisitors.bind(this)}>Exportera</button>
                              <button type="Button" className="dropdown-item Admin-small" data-toggle="modal" data-target="#deleteWarning">Radera</button>
                            </div>
                        </div>
                        <input id="adminSearchSmall" type="text" className="form-control" placeholder="Sök..." ref="adminSearchSmall" onChange={() => this.filterVisitors("small")}/>
                    </div>
                </div>
            </div>
        )
    }

  /**
   * Filter function for a list of visitors. Based on the input boolean "returningVisitor" the function will return a list of either
   * returning visitors, if the bool is true, or new visitors if the bool is set to false.
   * @param Visitors List of visitors to filter.
   * @param index The index of which visitor in the visitor list to check.
   * @param returningVisitor Boolean, if this is set to true then the function will return a list of all the returning visitors. If this is set to false then the function will return a list of new visitors.
   * @returns {*} Will return a list of either new visitors or returning visitors.
   */
    returningVisitorsHelpFilter(Visitors, index, returningVisitor) {
      if(returningVisitor){
        return Visitors.filter(x => x.email.toString().toLowerCase() === Visitors[index].email.toString().toLowerCase());
      }else {
        return Visitors.filter(x => x.email.toString().toLowerCase() !== Visitors[index].email.toString().toLowerCase());
      }
    }

  /**
   * Filters the table of visitors to only show returning visitors
   */
  returningVisitors(){
     let oldVisit = [];
      let tempVisitors = this.state.allVisitors.slice();
      for(var i = 0; i < tempVisitors.length; i++) {
        let filterVisitors = this.returningVisitorsHelpFilter(tempVisitors,i,true);
        if(filterVisitors.length > 1 ){
          oldVisit = oldVisit.concat(filterVisitors);
        }
        tempVisitors = this.returningVisitorsHelpFilter(tempVisitors,i,false);
        this.setList(oldVisit);
        this.setVisitors(oldVisit);
      }
    }

  /**
   * Filters the table of visitors to only show new visitors
   */
  newVisitors(){
      let newVisitors = [];
      let tempVisitors = this.state.allVisitors.slice();
      for(var i = 0; i < tempVisitors.length; i++) {
        let filterVisitors = this.returningVisitorsHelpFilter(tempVisitors,i,true);
        if(filterVisitors.length === 1 ) {
          newVisitors = newVisitors.concat(filterVisitors);
        }
        this.setVisitors(newVisitors);
        this.setList(newVisitors);
      }
    }

  /**
   * Displays all visitors in the admin visitors table
   */
  allVisitors(){
      let tempVisitors = this.state.allVisitors.slice();
      this.setVisitors(tempVisitors);
      this.setList(tempVisitors);
    }

  /**
   * Navbar
   * @returns {*}
   */
  navbar(){
      return (

          <nav className="navbar App-DarkBlueBackgroundcolour">
            <div className="container-fluid Admin-medium">
              <img className= "Admin-medium col-sm-2" alt="box-modul-loggo" src={require("../assets/static/pictures/BoxModul_White_RGB.png")}/>
              <div className="btn-group Admin-medium">
                <button className="btn btn-lg button-text App-DarkBlueBackgroundcolour mr-3" onClick={() => this.newVisitors()}>Nya besökare</button>
                <button className="btn btn-lg button-text App-DarkBlueBackgroundcolour mr-3"  onClick={() => this.returningVisitors()}>Återkommande</button>
                <button className="btn btn-lg button-text App-DarkBlueBackgroundcolour mr-2" onClick={() => this.updateTable()}>Alla besök </button>
                <div className="btn-group Admin-medium">
                  <button type="button btn-lg " className="btn btn-secondary dropdown-toggle" data-toggle="dropdown"
                          aria-haspopup="true" aria-expanded="false">
                  </button>
                  <div className="dropdown-menu dropdown-menu-right">
                    <button className="dropdown-item" type="button" onClick={() => this.handleLogout()}>Logga ut</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="container-fluid Admin-small">
              <img className="Admin-small navbar-brand loggo-small col-sm-1" alt="box-modul-loggo" src={require("../assets/static/pictures/BoxModul_White_RGB.png")}/>

              <div className="btn-group Admin-small">
                <button type="button" className="btn btn-secondary dropdown-toggle" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                  Meny
                </button>
                <div className="dropdown-menu dropdown-menu-leftgit ">
                  <button className="dropdown-item" type="button" onClick={() => this.newVisitors()}>Nya besökare</button>
                  <button className="dropdown-item" type="button" onClick={() => this.returningVisitors()}>Återkommande</button>
                  <button className="dropdown-item" type="button" onClick={() => this.updateTable()}>Alla besök</button>
                  <div className="dropdown-divider"></div>
                  <button className="dropdown-item" type="button" onClick={() => this.handleLogout()}>Logga ut</button>
                </div>
              </div>
            </div>
          </nav>
        )
    }

    render()
    {
      if(this.state.redirectToLogin){
        return <Redirect to = "/login" />
      }else if(this.state.redirectToError){
        return <Redirect to = "/AdminError" />
      }
        return (
            <div>
              <div className="row">
              {this.navbar()}
              </div>
              {this.headerButtons()}

                <div className="table Admin-table" >
                    <table className="table table-hover" key={"table"}>
                        <thead >
                        <tr >
                            <th scope="col" ><input id="checkAll" onClick={() => this.checkAll()}type="checkbox" name="All"/> Välj</th>
                            <th scope="col" className="table-header" onClick={() => this.sortTableDate(this.state.visitors)}>
                                <div>Datum <span className="glyphicon glyphicon-sort table-icon" aria-hidden="true"></span></div>
                            </th>
                            <th scope="col" className="table-header" onClick={() => this.sortTable(this.state.visitors, "name")}>
                                Namn <span className="glyphicon glyphicon-sort table-icon" aria-hidden="true"></span>
                            </th>
                            <th scope="col" className="table-header" onClick={() => this.sortTable(this.state.visitors, "company")}>
                                Företag <span className="glyphicon glyphicon-sort table-icon" aria-hidden="true"></span>
                            </th>
                            <th scope="col" className="table-header" onClick={() => this.sortTable(this.state.visitors, "email")}>
                                E-post <span className="glyphicon glyphicon-sort table-icon" aria-hidden="true"></span>
                            </th>
                            <th scope="col" className="table-header" onClick={() => this.sortTable(this.state.visitors, "nda")}>
                                NDA ifylld <span className="glyphicon glyphicon-sort table-icon" aria-hidden="true"></span>
                            </th>
                        </tr>
                        </thead>
                        <tbody key={"body"}>
                            {this.state.listItems}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

    /**
     * Deletes cookie and passes the user to login screen.
     */
    handleLogout() {
        let now = Date();
        document.cookie="BoxmodulSession=-1;expires="+now+";path=/";
        window.location.replace("/#/login");
    }
}

export default AdminTable;