import React, { Component } from 'react';
import './components.css';
import '../App.css';

class NdaAgreement extends Component {

    constructor(props) {
        super(props);
        this.state = {
          visitor: null,
        }
    }

    handleChangeView(view){
        this.props.changeView(view)
    }

    handleAccept(){
        this.props.acceptNda()
    }

    handleCancel(){
        this.props.cancelNda()
    }

  render() {
    return (
        <div className="col-sm-9">
            <div className="nda-popup-container">

                <div className="nda-popup">
                    <div className="row">
                        <h2 className="nda-title">
                        {this.props.strings.integrityPolicy}
                        <span className="the-icon" data-toggle="modal" data-target=".bd-example-modal-lg">
                            <span className="glyphicon glyphicon-remove-circle"></span>
                        </span>
                        </h2>
                    </div>

                    <div className="modal fade bd-example-modal-lg nda-cancel-popup" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-lg nda-cancel-popup-position">
                            <div className="modal-content nda-cancel-popup-content">
                            <p>{this.props.strings.cancelNda}</p>
                            <button data-dismiss="modal" onClick={this.handleCancel.bind(this)} className="nda-cancel-popup-button-left">{this.props.strings.yes}</button>
                            <button data-dismiss="modal" className="nda-cancel-popup-button-right">{this.props.strings.no}</button>
                            </div>
                        </div>
                    </div>

                    <br/>
                    <div className="nda-paragraph">
                      {this.props.strings.CONFIDENTIALITY_AGREEMEN}
                    </div>

                    <div className="nda-accept-box panel panel-default row">
                        <div className="nda-accept-text panel-body col-sm-9">{this.props.strings.acceptText} <br/> {this.props.strings.acceptMailText} </div>
                        <div className="nda-accept-button col-sm-3" onClick={this.handleAccept.bind(this)}>{this.props.strings.acceptButton}</div>
                    </div>
                </div>
            </div>
        </div>
    );
  }
}

export default NdaAgreement;
