import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './components.css';
import '../App.css';
import PopupWindow from './PopupWindow';
import ErrorPage from "../base/error/ErrorPage";
import GraphqlQueries from '../services/GraphqlQueries';
class ReturningVisitorForm extends Component {
    constructor(){
        super();
        this.state = {
            returningVisitor:{},
            popup: false,
            notFoundPopup: false,
            redirect: false
        }
    }

    handleSubmit(e){
        e.preventDefault();

        (new GraphqlQueries()).getVisitorExistsByEmail(this.refs.email.value).then(
            data => this.checkEmail(data.data), // if data is returned check if email exists
            error => (this.setState({redirect: true}))); // if server error redirect to error view
    }

    // checks the data returned from api, if the list of users with the entered email is > 0 then the visitor exists
    checkEmail(data){
        if(data.visitorExistsByEmail.success){
            this.setState({
                returningVisitor: {email: this.refs.email.value}
            }, function() {
                this.props.returningVisitor(this.state.returningVisitor);
            })
            this.props.changeView('nda');
        }else{
            this.setState({notFoundPopup: true})
        }
    }

    checkFields(){
        var email = document.getElementById("emailInput").value;
        if(email.length > 3 && (email.toString().indexOf("@") !== -1) && (email.toString().split("@")[1].length > 0)){
            document.getElementById("submitForm").disabled = false;
            document.getElementById("submitForm").className="btn box-button-left-blue";
        }else{
            document.getElementById("submitForm").className="btn box-button-left";
            document.getElementById("submitForm").disabled = true;
        }
    }

    handleChangeView(view){
        this.props.changeView(view)
    }

    dismissPopup(){
        this.props.changeView('homepage')
    }

    dismissNotFoundPopup(){
        this.setState({notFoundPopup: false})
    }
    
    componentDidMount() {
        this.setState({popup:this.props.showPopup})
    }

  render() {

    if(this.state.redirect){
        return <ErrorPage strings={this.props.strings} />
      }

    return (
      <div className="col-sm-9 home-container">

        {this.state.popup &&
            <PopupWindow dismissAction={this.dismissPopup.bind(this)} strings={this.props.strings} popupText={this.props.strings.popupThanks} buttonText={this.props.strings.newVisit}/>
        }

        {this.state.notFoundPopup &&
            <PopupWindow dismissAction={this.dismissNotFoundPopup.bind(this)} strings={this.props.strings} popupText={this.props.strings.notFound} buttonText={this.props.strings.tryAgain}/>
        }

        <h1 className="form-title" id="returningVisitorTitle">{this.props.strings.returningVisitor}</h1>
        <h3 className="returning-text">{this.props.strings.returningVisitorText}</h3>
        <form className="box-content form-group" autoComplete="off" onSubmit={this.handleSubmit.bind(this)}>
            <br/>
            <div className="input-group">
                <input id="emailInput" onChange={this.checkFields} type="email" ref="email" className="box-input form-control info-input" placeholder={this.props.strings.email}/>
                <div className="input-group-append dropup">
                    <button id="inputInfo" className="btn btn-outline-secondary glyphicon glyphicon-info-sign box-input" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button>
                    <div className="dropdown-menu dropdown-menu-input-name dropdown-menu-right">
                        <button className="dropdown-item dropdown-item-input-name">Ex: no-reply@boxmodul.se</button>
                    </div>
                </div>
            </div>

            <br />
            <button id="submitForm" disabled type="submit" className="btn box-button-left" >{this.props.strings.register}</button>
            <button onClick={this.handleChangeView.bind(this, 'homepage')} className="btn box-button-right" >{this.props.strings.back}</button>
        </form>
      </div>
    );
  }
}

ReturningVisitorForm.propTypes = {
    returningVisitor: PropTypes.func
}

export default ReturningVisitorForm;
