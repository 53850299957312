import React, { Component } from 'react';
import BaseSideBar from './base/baseSidebar/BaseSidebar';
import AdminErrorPage from './Admin/AdminErrorPage';
import CurrentView from './components/CurrentView';
import './components/components.css';
import './App.css';
import ReactDOM from "react-dom";
import AdminLogin from "./Admin/Admin-login";
import AdminTable from './Admin/Admin-table';
//import AdminRegistration from './Admin/Admin-Registration';

import {
  HashRouter as Router,
  Route,
} from 'react-router-dom'

const Home = () => (
  <div className="row">
    <BaseSideBar/>
    <CurrentView />
  </div>
)

const AdminLoginView = () =>(
  <AdminLogin />
)

class App extends Component {
  constructor(){
    super();
    this.state = {
      language: "english"
    }
  }

  render() {
      return (
          <Router>
              <div className="container-fluid">
                  <Route exact path="/" component={Home}/>
                  <Route path="/admin" component={AdminTable}/>
                  <Route path="/adminError" component={AdminErrorPage}/>
                  <Route path="/login" component={AdminLoginView}/>
                  {/*<Route path="/adminregistration" component={AdminRegistration}/> */}
              </div>
          </Router>
      );
    }
}
/** To test admin add <Admin/> in render and remove sidebar and curretnView**/
ReactDOM.render(<App/>, document.getElementById('root'));

export default App;