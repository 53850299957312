import React, { Component } from 'react';

import AdminLoginTemp from "./Admin-login-template";
import AdminLoginNav from "./Admin-login-navbar";

class AdminLogin extends Component {

  render(){
    return (
      <div>
        <div className="row">
          <AdminLoginNav/>
        </div>
        <div className="row">
          <AdminLoginTemp/>
        </div>
      </div>
    )
  };
}

export default AdminLogin;