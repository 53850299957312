import React, { Component } from 'react';
import '../App.css';
import './components.css';

class PopupWindow extends Component{


    handleDismiss(element, e){
        if(element==='dismiss'){
            this.props.dismissAction()
        }else{
            e.stopPropagation();
        }
    }

    componentDidMount() {
        // Sets a timer to dismiss the popup after 8 senconds
        this.timerID =  setTimeout(  () => this.redirect(),8000)
    }

    /**
     * Redirect function to dismiss the popup and return to the homescreen
     */
    redirect(){
        this.props.dismissAction()
    }

    componentWillUnmount(){
        // Destorys the timer after the component is unmounted
        clearInterval(this.timerID)
    }

    render() {
        return (
            <div id="popupid" className="homepage-popup" onClick={this.handleDismiss.bind(this, 'dismiss')}>
                <div className="homepage-popup-position" onClick={this.handleDismiss.bind(this, 'doNothing')}>
                    <div className="homepage-popup-content">
                    <p id="popupText">{this.props.popupText}</p>
                    <button onClick={this.handleDismiss.bind(this, 'dismiss')} id="popupButtonText" className="homepage-popup-button">{this.props.buttonText}</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default PopupWindow;