import React, { Component } from 'react';
import NewVisitorForm from './NewVisitorForm'
import ReturningVisitorForm from './ReturningVisitorForm'
import NdaAgreement from './NdaAgreement'
import HomePage from './HomePage';
import Strings from '../text/strings';
import GraphqlQueries from '../services/GraphqlQueries';
import ErrorPage from "../base/error/ErrorPage";

class CurrentView extends Component {
    constructor(props){
      super(props);
      this.state = {
        strings: Strings.Strings.enStrings,
        view: 'homepage',
        newVisitor: null,
        company: '',
        returningVisitor: null,
        ndaAccepted: false,
        ndaCanceled: false,
        redirect: false
      }
    }
  
    changeLanguage(newLanguage){
      if(newLanguage==="english"){
        this.setState({
          strings: Strings.Strings.enStrings
        })
      }else if(newLanguage==="swedish"){
        this.setState((prevState) => {
          return {strings: Strings.Strings.sweStrings}
        });
      }
  }

  // changes the displayed view by updating the view state wihich will trigger switch in render to change
  setView(newView){
    this.setState({view: newView})
  }

  // A new visitor is added to newVisitor state but should not be submited untill user accepts the agreement
  newVisitor(registeredVisitor){
      this.setState({company: registeredVisitor.company});
    this.setState({newVisitor:registeredVisitor},  function(){
    })
  }

  // Triggered after a returning visitor has entered their email and registered. 
  returningVisitor(registeredVisitor){
    const graph = new GraphqlQueries();
    graph.getCompanyByMail(registeredVisitor.email).then(data =>{
      this.setState({company: data.data.companyByEmail.company})
    });
    this.setState({returningVisitor:registeredVisitor}, function(){
    })
  }

  /* Called when a visitor has accepted the agreement.
  * If it's a new visitor a new visitor is added to database
  * If it's a returning visitor, that visitor gets an updated entry in the database
  */
  acceptNda(){
    const graph = new GraphqlQueries();
    if (this.state.newVisitor) {
        graph.addVisitor(this.state.newVisitor)
          .then( data => {
            if(!data.data.createVisitor.success){
              this.setState({redirect: true})
            }else{
              graph.sendMailToVisitor(this.state.newVisitor.email, this.state.strings.lang) //Catch error
              this.setState({
                ndaAccepted: true,
                view: 'newVisitor'
              })
            }
          }, error => (
            this.setState({redirect: true})
            )
          ); // creates a graphql mutation and adds the new visitor to database
    }else if(this.state.returningVisitor){
        graph.returningVisitor(this.state.returningVisitor.email)
          .then(data => {
            if(!data.data.updateVisitor.success){
              this.setState({redirect: true})
            }else{
              this.setState({
                ndaAccepted: true,
                view: 'returningVisitor'
              })
            }
          }, error => (
            this.setState({redirect: true})
          )); // creates a graphql mutation and adds the new visitor to database
    }
  }

  // Called when a visitor cancels the agreement. Should change to HomePage with a popup
  cancelNda(){
    this.setState(
      {
        ndaCanceled: true,
        view: 'homepage'
      })
  }

  // Always called at homepage to avoid errors with popups. HomePage should always reset the state.
  resetState(){
    this.setState(
      {
        ndaAccepted: false,
        ndaCanceled: false,
        newVisitor: null,
        returningVisitor: null,
        company: '',
      })
  }

    render() {
        if(this.state.redirect){
          return <ErrorPage strings={this.state.strings} />
        }
        const homepage = <HomePage 
        changeLanguage={this.changeLanguage.bind(this)} 
        strings={this.state.strings} 
        changeView={this.setView.bind(this)} 
        resetState={this.resetState.bind(this)} 
        showPopup={this.state.ndaCanceled}/>

        const newVisit = <NewVisitorForm 
        newVisitor={this.newVisitor.bind(this)} 
        strings={this.state.strings} 
        changeView={this.setView.bind(this)} 
        showPopup={this.state.ndaAccepted}/>

        const returningVisit = <ReturningVisitorForm 
        returningVisitor={this.returningVisitor.bind(this)} 
        strings={this.state.strings} 
        changeView={this.setView.bind(this)} 
        showPopup={this.state.ndaAccepted} />

        const nda = <NdaAgreement 
        strings={this.state.strings} 
        company = {this.state.company} 
        changeView={this.setView.bind(this)} 
        acceptNda={this.acceptNda.bind(this)} 
        cancelNda={this.cancelNda.bind(this)}/>
        let View = homepage

        switch(this.state.view){
            case 'homepage':
            View = homepage
            break;
      
            case 'newVisitor':
            View = newVisit
            break;

            case 'returningVisitor':
            View = returningVisit
            break;

            case 'nda':
            View = nda
            break;

            default:
            View = homepage;
            break;
          }

      return (
        View
      );
    }
  }

  export default CurrentView;