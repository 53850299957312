import React, { Component } from 'react';
import '../App.css';
import './components.css';
import PopupWindow from './PopupWindow';

class HomePage extends Component{
    constructor(props){
        super(props)
        this.state = {
            strings: this.props.strings,
            date: new Date().toLocaleDateString('sv-SE'),
            time: new Date().toLocaleTimeString('sv-sE'),
            popup: false
        }
    }

    tick() {
        this.setState({
          date: new Date().toLocaleDateString('sv-SE'),
          time: new Date().toLocaleTimeString('sv-sE')
        });
      }
    
    componentDidMount() {
        this.props.resetState()
        
        this.setState({strings:this.props.strings}, function(){
            this.setState({popupText: this.props.strings.popupThanks})
        })

        this.setState({popup: this.props.showPopup})

        // starts the clock
        this.timerID = setInterval(
          () => this.tick(),
          1000
        );
    }

    componentWillUnmount() {
        // destroys the clock interval
        clearInterval(this.timerID);
      }

    // changes 
    changeLanguage(newLanguage){
        this.props.changeLanguage(newLanguage)
        // Necessary "hack" to make sure the language is changed
        this.setState({strings: this.props.strings}, function(){
            this.setState(
                {
                    strings: this.props.strings
                })
        }) 
    }


    dismissPopup(){
        this.setState({popup: false})
    }

    render() {
        return (
                <div className="col-sm-9 home-container">
                    <div className="HomeAlign">

                        {this.state.popup &&
                        <PopupWindow dismissAction={this.dismissPopup.bind(this)} strings={this.props.strings} popupText={this.props.strings.contactEmployee} buttonText={this.props.strings.newVisit}/>
                        }

                      <div className="row">
                        <div className="btn-group col-sm-9 offset-sm-1 justify-content-center">
                                <input onClick={this.changeLanguage.bind(this, 'english')} id="englishFlag" type="image" className="icon-size mr-4" alt = "eng-flag" src={require("../assets/static/pictures/england-flag-icon.png")}/>
                                <input onClick={this.changeLanguage.bind(this, 'swedish')} id="swedishFlag" type="image" className="icon-size" alt = "swe-flag" src={require('../assets/static/pictures/sweden-flag-icon.jpg')}/>
                        </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-9 offset-sm-1">
                                <h4 className="text-center"><i>{this.state.strings.welcome}</i></h4>
                            </div>
                        </div>

                        <br/>

                        <div className="row">
                            <div className="col-sm-9 offset-sm-1">
                                <h1 className="text-center"><b id="visitorLogText">{this.state.strings.VisitorLogg}</b></h1>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-9 offset-sm-1" id="clock">
                                <h5 className="clock text-center">{this.state.date} {this.state.time}</h5>
                            </div>
                        </div>

                        <br/>
                        <br/>

                    <div className="row">
                        <div className="btn-group col-sm-9 offset-sm-1 justify-content-center">
                                <button type="Button" id="newVisitorButton" onClick={this.props.changeView.bind(this, 'newVisitor')} className="btn homeButton button-text App-DarkBlueBackgroundcolour mr-4">{this.state.strings.newVisitorButton}</button>
                                <button type="Button" id="returningVisitorButton" onClick={this.props.changeView.bind(this, 'returningVisitor')} className="btn  homeButton button-text App-DarkBlueBackgroundcolour">{this.state.strings.returningVisitorButton}</button>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default HomePage;