import React, {Component} from 'react';
import '../App.css';
import './Admin.css';
import strings from "../text/strings";
import { Redirect } from 'react-router-dom';
import CookieService from '../services/CookieService';


class AdminLoginTemp extends Component {
  constructor() {
    super();
    this.state = {
      loginSuccess: false,
      redirectToError: false,
      warning: <p></p>
    }
  }

  getToken(){
    new CookieService().getToken();
  } 


  /* Takes the email and password entered in the form and runs it through checks to make
   * sure the data is valid.
   */

  handleSubmit(e) {

    var mailValue = document.getElementById("inputEmail").value;
    var passValue = document.getElementById("inputPassword").value;

    if (!(passValue.length > 50) && !(passValue.length < 5)) {

      //Send post request
      var xhr = new XMLHttpRequest();
      xhr.open("POST", strings.Strings.devStrings.prodUri + '/auth');
      xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
      xhr.onreadystatechange = function() {//Call a function when the state changes.

      //Call a function when the state changes
      if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
        if (xhr.getResponseHeader("Success") === "true") {
          document.cookie = "BoxmodulSession=" + xhr.getResponseHeader("Authorization") + ";expires=" + expirationTime() + ";path=/";
          this.setState({ loginSuccess:true })
          } else {
            document.getElementById("admin-login-form").reset();
            document.getElementById("loginButton").disabled = true;
            this.setState({warning: <p>Invalid E-mail or Password</p>})
          }
        }else if(xhr.readyState === XMLHttpRequest.DONE && xhr.status === 0){
          this.setState({redirectToError:true})
        }
      }.bind(this)
      xhr.send("email="+mailValue+"&password="+passValue)
      //Set authorization header to  "Bearer " + token
    }
    e.preventDefault();
  }

  /* Makes sure the data is valid before you can press the login button */
  checkFields() {
    var mailValue = document.getElementById("inputEmail").value;
    var passValue = document.getElementById("inputPassword").value;

    if(mailValue && passValue) {
      if ((passValue.length < 5) || (passValue.length > 50)) {
        document.getElementById("loginButton").disabled = true;
      } else {
        document.getElementById("loginButton").disabled = false;
      }
    } else {
      document.getElementById("loginButton").disabled = true;
    }
  }


  render() {
    if(this.state.loginSuccess){
      return <Redirect to = "/admin" />
    }else if(this.state.redirectToError){
      return <Redirect to = "/AdminError" />
    }

    return(

      <div className="container Login">
        <form id="admin-login-form" onSubmit={this.handleSubmit.bind(this)}>
          <div className="form-group">
            <input type="email" className="form-control login-input" onChange={this.checkFields} id="inputEmail" aria-describedby="emailHelp" placeholder="E-mail"></input>
          </div>
          <div className="form-group">
            <input type="password" className="form-control login-input" onChange={this.checkFields} id="inputPassword" placeholder="Password"></input>
            <div className="warning-label">{this.state.warning}</div>
          </div>
          <button disabled type="submit" className="btn btn-primary btn-lg btn-block" id="loginButton">Login</button>
        </form>
      </div>
    );
  }
}

function expirationTime() {
    var now = new Date();
    var timeNow = now.getTime();
    var expirationTime = timeNow+86400000;
    now.setTime(expirationTime)

    return now;
}
export default AdminLoginTemp;
