import React from 'react';
export default {
    Strings: {
        enStrings: {
            lang: "EN",
            yes: "Yes",
            no: "No",
            welcome: "Welcome to Boxmodul. Please register your visit here.",
            newVisitor: "NEW VISITOR",
            returningVisitor: "RETURNING VISITOR",
            returningVisitorText: "Welcome back, please enter the E-mail used in your last visit",
            name: "Name",
            email: "E-mail",
            phone: "Phone",
            company: "Company",
            register: "Register",
            back: "Back",
            VisitorLogg: "VISITORS LOG",
            newVisitorButton: "New Visitor",
            returningVisitorButton: "Returning Visitor",
            integrityPolicy: <b>Privacy Policy</b>,
            acceptButton: "Accept",
            acceptText: "By clicking accept you agree to the terms stated above.",
            acceptMailText: "The complete, in detail, privacy policy will be sent to your email",
            popupThanks: "Thank you for signing in",
            newVisit: "New visit",
            contactEmployee: "Contact an employee before entering the facility",
            cancelNda: "Do you really want to cancel your registration?",
            notFound: "E-mail doesn't belong to a previous visitor",
            tryAgain: "Try again",
            errorTextHeader: "Sorry!",
            errorText: "Something went wrong, please try again!",
            none: "None",
            help: "Help",
            explanation: "Explanation",
            example: "Example",
            helpFirstAndSureName: "Forename Surname",
            helpCountryAndPhone: "Country code and phone number",
            helpMail: "Company email",
            helpCompany: "Full company name",
            dismiss:"Dismiss",
            date: "Dated at(in swedish date format), ",
          Recipient: "This agreement, effective on the date of signature of the second to sign of the parties hereto, is between Boxmodul AB (hereinafter ”Box”), a Swedish company, having its registered offices at Kabelgatan 9, SE-943 31 Öjebyn, Sweden, and",
          CONFIDENTIALITY_AGREEMEN: <div>
          <p><b>Last updated: </b> 2021</p>
          <p>Box Modul AB is responsible for your data. This summary informs you how this data will be used and the purpose as to why we ask you to register this information.</p>
          <p>We require all our visitors to register their visit in this application when visiting our office/factory. The data stored in this application is managed and maintained by Box Modul AB. The application is developed by Arctic Group AB.</p>             

          <h3><b>Information, Collection and Use</b></h3>
          <p>The reason for collecting data about your visit is to prevent visitors from leaking or revealing information about our products or our customers without a trace.</p>

          <h3><b>Log Data</b></h3>
          <p>The data gathered and stored includes information such as name, e-mail and current employer. This data is stored for 12 months and after that deleted permanently.</p>

          <h3><b>Communication</b></h3>
          <p>We may also use this information to contact you with marketing or promotional material that we might find relevant or useful to you.</p>

          <h3><b>Security</b></h3>
          <p>The security of your personal information is extremely important to us. We can assure you that we have taken all necessary actions to ensure your data is secure.</p>           
          <p>The full details of our privacy policy will be sent to you by e-mail once you complete this form.</p>
          </div>
        },
        sweStrings: {
            lang: "SWE",
            yes: "Ja",
            no: "Nej",
            welcome: "Välkommen till Boxmodul. Vänligen registrera ditt besök här.",
            newVisitor: "NY BESÖKARE",
            returningVisitor: "ÅTERKOMMANDE BESÖKARE",
            returningVisitorText: "Välkommen tillbaka, vänligen ange samma E-post som vid förra besöket",
            name: "Namn",
            email: "E-post",
            phone: "Telefonnummer",
            company: "Företag",
            register: "Registrera",
            back: "Bakåt",
            VisitorLogg: "BESÖKSLOGG",
            newVisitorButton: "Ny besökare",
            returningVisitorButton: "Återkommande besökare",
            integrityPolicy: <b>Integritetspolicy</b>,
            acceptButton: "Acceptera",
            acceptText: "Genom att klicka acceptera så godkänner du integritetspolicy ovan.",
            acceptMailText: "En komplett, detaljerad, integritetspolicy kommer att skickas till din e-post",
            popupThanks: "Tack för att du registrerar dig",
            newVisit: "Nytt besök",
            contactEmployee: "Kontakta en anställd innan du beträder anläggningen",
            cancelNda: "Är du säker att du vill avbryta registreringen?",
            notFound: "E-posten tillhör ej en föredetta besökare",
            tryAgain: "Försök igen",
            errorTextHeader: "Vi ber om ursäkt!",
            errorText: "Något gick fel, vänligen försök igen!",
            none: "Ingen",
            help: "Hjälp",
            explanation: "Förklaring",
            example: "Exempel",
            helpFirstAndSureName: "Förnamn Efternamn",
            helpCountryAndPhone: "Landsnummer och mobilnummer",
            helpMail: "Företags E-post",
            helpCompany: "Företags namn",
            dismiss:"Stäng",
            Recipient: "Undertecknat bolag,",
            date: "Datum: ",
            CONFIDENTIALITY_AGREEMEN: <div>
            <p><b>Senast uppdaterad: </b> 2021</p>
            <p>Box Modul AB ansvarar för dina uppgifter. Denna sammanfattning informerar dig om hur denna data ska användas och syftet till varför vi ber dig att registrera denna information.</p>
            <p>Vi kräver att alla besökare registrerar sitt besök i detta formulär när de besöker vårt kontor/fabrik. Den data som lagras i den här applikationen hanteras och underhålls av Box Modul AB. Applikationen är utvecklad av Arctic Group AB.</p>             

            <h3><b>Information, samling och användning</b></h3>
            <p>Anledningen till att vi samlar in uppgifter om ditt besök är för att förhindra besökare från att sprida eller avslöja information om våra produkter eller våra kunder utan ett spår.</p>

            <h3><b>Loggdata</b></h3>
            <p>Uppgifterna som samlas in och lagras innehåller information som namn, e-post och nuvarande arbetsgivare. Denna data lagras i 12 månader och raderas därefter permanent.</p>

            <h3><b>Kommunikation</b></h3>
            <p>Vi kan också använda denna information för att kontakta dig med marknadsförings- eller reklammaterial som vi finner relevant eller användbart för dig.</p>

            <h3><b>Säkerhet</b></h3>
            <p>Säkerhet för din personliga information är oerhört viktig för oss. Vi kan försäkra dig om att vi har vidtagit alla nödvändiga åtgärder för att säkerställa att dina uppgifter är säkra.</p>           
            <p>De fullständiga detaljerna i vår integritetspolicy skickas till dig via e-post när du fyllt i detta formulär.</p>
            </div>
        }, devStrings:{
            prodUri: 'https://visitorlog.boxmodul.se',

      }
    },

}


